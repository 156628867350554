<template>
  <div class="container inside-wrapper" id="landing">

    <div class="row back-image back-blue banner-1" style="">
        <div class="col-md-7 col-heading">
            <div class="banner-text">
                <h3>We Help Non-Profits Succeed By Donating Technical Services For FREE</h3>
                <p>
                    Let our team update your website and create eye catching marketing campaigns while providing
                    you with the tools to capture relevant data to increase donations and make fundraising easier.
                </p>
                <ul type="none" class="social-landing">
                    <li><i class="fa fa-minus text-blue">&nbsp;&nbsp;</i><span>Does your organization need more donations and better avenues of fundraising?</span></li>
                    <li><i class="fa fa-minus text-red">&nbsp;&nbsp;</i><span>Can you communicate better with the people who support you most?</span></li>
                    <li><i class="fa fa-minus text-blue">&nbsp;&nbsp;</i><span>Do you promote events and activities only through social media?</span></li>
                    <li><i class="fa fa-minus text-red">&nbsp;&nbsp;</i><span>Are you in need of better data capture and analysis?</span></li>
                </ul>
                <p>
                    If you answered Yes to any of these questions then fill out the form and we will be glad to consult with you on how we can help your organization.
                </p>
            </div>
        </div>

        <div class="col-md-4 ">
            <div class="back-white contactcontent contact-form-landing">
                <div class="row">
                    <div class="col-sm-12">
                        <a id="consultation"></a>
                        <h2 class="heading wow fadeInDown animated text-blue">Get Your FREE Consultation</h2>
                    </div>
                </div>
                <div class="landing-contact">
                    <form id="contact-form" method="post" action="/Support/Contact" role="form">
                        <div class="messages"></div>
                        <div class="controls">

                            <div class="row">
                                <div class="col-md-12">
                                    <div class="form-group">
                                        <label>Name</label>
                                        <input id="form_name" type="text" name="name" class="form-control" required="" data-error="Name is required.">
                                        <div class="help-block with-errors"></div>
                                    </div>
                                </div>
                                <div class="col-md-12">
                                    <div class="form-group">
                                        <label>Phone</label>
                                        <input id="form_phone" type="text" name="phone" class="form-control">
                                        <div class="help-block with-errors"></div>
                                    </div>
                                </div>
                                <div class="col-md-12">
                                    <div class="form-group">
                                        <label>Email</label>
                                        <input id="form_email" type="email" name="email" class="form-control" required="required" data-error="Valid email is required.">
                                        <input id="form_location" type="text" name="location" class="form-control hide">
                                        <div class="help-block with-errors"></div>
                                    </div>
                                </div>
                            </div>
                            <br>
                            <div class="row">
                                <div class="col-md-12">
                                    <div class="form-group">
                                        <label>Organization</label>
                                        <textarea id="form_message" name="message" class="form-control" rows="1" required="" data-error="Please, tell us the name of your organization."></textarea>
                                        <div class="help-block with-errors"></div>
                                    </div>
                                </div>
                                <div class="col-md-12">
                                    <button class="btn-blue">Send </button>
                                </div>
                            </div>
                        </div>
                        <input name="__RequestVerificationToken" type="hidden" value="CfDJ8GhqxgZ9uGBPtIFHGDzrDOgfhYy_H3KEzHCQvmNt2wfJ8MdDKJZ3RgKfeOuk3-rDCv-h2W55B_29ewY0X8EhPoAwUiUwbHdW7IdzBnTvikWBU9rAnL1hDQaiVMKzFVMEB6489J99-oTixCkI92Q9Ixs">
                    </form>
                </div>
            </div>
        </div>

    </div>


    <div class="row about">
        <div class="col-md-12 col-heading border-right ">
            <a id="whatwedo"></a>
            <h4 class="heading wow fadeInDown animated">Why Ocean Ring Technologies?</h4>
            <p class="text-dark wow fadeInUp animated text-dark subheading text-justify">
                Non-profit organizations don't always have the budget to hire someone who can help promote
                and provide support for the organization to achieve its goals. Here are a few examples of how
                ORT has helped organizations:
            </p>
            <ul type="none" class="whyORT">
                <li>
                    <i class="fa fa-minus text-blue">&nbsp;&nbsp;</i><span>
                        A youth education program needed to improve the curriculum used to teach students how to code.
                        We provided tools and a new curriculum which modernized the material and increased student
                        hiring rates by 175%.
                    </span>
                </li>
                <li>
                    <i class="fa fa-minus text-red">&nbsp;&nbsp;</i><span>
                        Data analysis for a food pantry which needed help identifying which foods to focus on 
                        for donations and improve inventory tracking. A focused campaign helped increase 
                        donations 150% after providing an analysis while also building a mobile tool to help track 
                        supply and demand.
                    </span>
                </li>
                <li>
                    <i class="fa fa-minus text-blue">&nbsp;&nbsp;</i><span>
                        Re-built web application for a national surplus lines non-profit to make all applications
                        and government forms available online replacing the manual process of email and the ability
                        for staff to run reports on demand.
                    </span>
                </li>
                <li>
                    <i class="fa fa-minus text-red">&nbsp;&nbsp;</i><span>
                        Increased attendance for programs and activities of a youth non-profit through improved
                        forms and marketing channels. Setup various payment options allowing parents to sign up their
                        children faster while also showing the non-profit how to better track registrations.
                    </span>
                </li>
                <li>
                    <i class="fa fa-minus text-blue">&nbsp;&nbsp;</i><span>
                        Assisted a mother whose mission was to stand against violence against women by building 
                        a new website for her organization and setting up new social media accounts. Afterwards, 
                        multiple campaigns were launched promoting her talks which increased speaking requests by
                        100%.
                    </span>
                </li>
            </ul>
            <p class="text-dark wow fadeInUp animated text-dark subheading text-justify">
                <strong>Fill out the form above to receive a free consultation on how we can improve your workflow, increase donations and promote your organization.</strong>
            </p>
        </div>
    </div>

    <div class="about row landing-image">
        <div class="col-md-6 back-image landing-image min-height hidden-sm hidden-xs back-image-1">
            &nbsp;
        </div>

        <div class="col-md-6 col-heading border-left ">
            <h2 class="heading wow fadeInDown animated ">Our Company Values</h2>
            <p class="text-dark wow fadeInUp animated text-justify">
            </p><h3 class="text-dark">
                Members of Ocean Ring Technologies have been volunteering their time and providing free technical
                support to non-profit and social good oganizations since 2014. Each of our members support the values
                shared by our company:
            </h3><p></p>
            <br>
            <ul type="none" class="whyORT">
                <li><i class="fa fa-minus text-blue">&nbsp;&nbsp;</i><span>Support Organizations Striving for Positive Change</span></li>
                <li><i class="fa fa-minus text-red">&nbsp;&nbsp;</i><span>Treat All People as Equals Regardless of Gender, Race or Orientation</span></li>
                <li><i class="fa fa-minus text-blue">&nbsp;&nbsp;</i><span>Projects Need to Lessen The Footprint Upon the Planet</span></li>
                <li><i class="fa fa-minus text-red">&nbsp;&nbsp;</i><span>Assist in Empowering the Youth of Today</span></li>
            </ul>
            <p></p>
            <br>
            <a href="#consultation" class="btn-blue">Learn more during your free consultation</a>
        </div>
    </div>
    <br><br>
    <div class="row">
        <div id="landing">
            <div class="col-sm-12 col-pad ">
                <h2 class="text-red text-bold">We Work With Starups Also!</h2><br>
                <p>
                    </p><h3 class="text-dark">
                        If you are a startup looking for technical services then we would be glad to talk if you are willing to sponsor a non-profit in our
                        program. Every day a non-profit or startup is referred to us and our program. We strive to help
                        every person who seeks our assistance and wants to make a positive impact in the world.
                    </h3>
                <p></p>
                <br>
                <p>
                    </p><h3 class="text-dark">
                        All projects are FIRST COME, FIRST SERVE. The sooner you contact us, the sooner we can assist you and begin the process of
                        helping you achieve your goals! Submit the form above to learn more.
                    </h3>
                <p></p>
                <p></p>
                <br>
                <a href="#consultation" class="btn-blue">We Can Help</a>
            </div>
        </div>
    </div>
    <div class="row contact-fourth back-red">
        <!--<div class="col-sm-3 text-left">
            <a href="/home"><h2 class="heading text-light"><i class="fa fa-long-arrow-left"> </i> Learn </h2></a>
        </div>-->
        <!--<div class="col-sm-6">
            <p></p>
        </div>
        <div class="col-sm-3 text-right">
            <a href="#"><h2 class="heading text-light">Team <i class="fa fa-long-arrow-right"> </i></h2></a>
        </div> -->
    </div>



                
            </div>
</template>

<script>
export default {
  name: "support",
  async mounted() {
    window.scrollTo(0, 0);
  },
};
</script>

<style scoped>
.back-image-1 {
        background-image: url('../assets/img/landing-img-1.jpg');
    }
</style>
